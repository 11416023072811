const hover = (lang) => ({
  type: 'HOVER',
  lang
})

const actions = {
  hover
}

export default actions
